

























































































































/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataResponseListJoinInvoiceNumber } from "@/models/interface/invoice.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { invoiceServices } from "@/services/invoice.service";
import moment from "moment";
import { Component, Ref, Vue } from "vue-property-decorator";

@Component
export default class JoinInvoiceList extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  @Ref("searchForm") searchForm;
  form = {
    branch: "",
    joinInvoiceNo: "",
    customerName: "",
    joinInvoiceDate: null,
    currency: "",
  };
  joinInvoiceNoOption = {
    data: [] as DataResponseListJoinInvoiceNumber[],
    search: "",
    fetching: true,
  };
  page = 1;
  limit = 10;
  totalData = 0;
  loadingSubmit = false;
  dataSource: DataResponseListJoinInvoiceNumber[] = [];
  tableColumns: ColumnTableCustom[] = [
    {
      title: this.$t("lbl_branch").toString(),
      dataIndex: "branchWarehouse",
      key: "branchWarehouse",
      width: 150,
    },
    {
      title: this.$t("lbl_join_invoice_no").toString(),
      dataIndex: "documentNumber",
      key: "documentNumber",
      width: 200,
    },
    {
      title: this.$t("lbl_join_invoice_date").toString(),
      dataIndex: "date",
      key: "date",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_customer_name").toString(),
      dataIndex: "customerName",
      key: "customerName",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_currency").toString(),
      dataIndex: "currency",
      key: "currency",
      width: 150,
    },
    {
      title: this.$t("lbl_total").toString(),
      dataIndex: "total",
      key: "total",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_status").toString(),
      dataIndex: "status",
      key: "status",
      width: 150,
    },
    {
      title: this.$t("lbl_action").toString(),
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["view"],
      width: 120,
      align: "center",
      fixed: "right",
    },
  ];

  assignSearch(key, value, and): string {
    if (key === "branch" && value)
      return and + `branchWarehouse.secureId~${value}`;
    else if (key === "joinInvoiceNo" && value)
      return and + `documentNumber~${value}`;
    else if (key === "customerName" && value)
      return and + `customer.secureId~*${value}*`;
    else if (key === "joinInvoiceDate" && value && value.length > 1)
      return (
        and +
        `date>=${moment(value[0])
          .set({ hour: 0, minute: 0, second: 0 })
          .utcOffset("+07")
          .format()}_AND_date<=${moment(value[1])
          .set({ hour: 23, minute: 59, second: 59 })
          .utcOffset("+07")
          .format()}`
      );
    else if (key === "currency" && value)
      return and + `priceCurrency.currencyCode~${value}`;
    else return "";
  }

  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }

  handleSubmit(): void {
    this.searchForm.validate(valid => {
      if (valid) {
        const params: RequestQueryParamsModel = {
          page: this.page - 1,
          limit: this.limit,
          sorts: "createdDate:desc",
        };
        params.search = this.dynamicSearch(this.form);
        this.loadingSubmit = true;
        invoiceServices
          .getListJoinInvoiceNumber(params)
          .then(response => {
            this.totalData = response.totalElements;
            this.dataSource = response.data.map((dataMap, index) => {
              return {
                ...dataMap,
                key: index,
                date: moment(dataMap.date).format(DEFAULT_DATE_FORMAT),
                total: dataMap.invoiceJoinLines.reduce((a, b) => {
                  return a + b.totalInvoice;
                }, 0),
              };
            });
          })
          .finally(() => (this.loadingSubmit = false));
      }
    });
  }

  resetForm(): void {
    this.searchForm.resetFields();
  }

  createNew(): void {
    this.$router.push("/accountreceivables/join-invoices/create");
  }

  responsePageSizeChange(response: ResponsePagination): void {
    this.limit = response.size;
    this.page = 1;
    this.handleSubmit();
  }

  responseCurrentPageChange(response: ResponsePagination): void {
    this.page = response.page;
    this.handleSubmit();
  }

  viewRow(row): void {
    this.$router.push(`/accountreceivables/join-invoices/view/${row.data.id}`);
  }

  getJoinInvoiceNo(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: this.page - 1,
      limit: this.limit,
    };
    if (valueSearch) params.search = `documentNumber~*${valueSearch}*`;
    this.joinInvoiceNoOption.fetching = true;
    invoiceServices
      .getListJoinInvoiceNumber(params)
      .then(response => {
        this.joinInvoiceNoOption.data = response.data;
      })
      .finally(() => (this.joinInvoiceNoOption.fetching = false));
  }

  created() {
    this.getJoinInvoiceNo("");
  }
}
